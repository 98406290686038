



export const Products = (props) => {
  return (
    <div id='products' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Products</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4'>
                {' '}
                <div className="circle">
                  <img src={d.img} alt={d.name}></img>
                </div>

                <div className='product-desc'>
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
