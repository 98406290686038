export const Footer = (props) => {

  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 ShapeBundle. Proud.{' '}

          </p>
        </div>
      </div>
    </div>
  )
}
