import React from 'react';

import HoverVideoPlayer from "react-hover-video-player";

export const Shapes = (props) => {
  return (
    <div id='shapes'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>ShapeBundle 3D Design</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4'>
                <div className='shapes'>

                  <HoverVideoPlayer videoSrc={d.video}
                    width="90%"
                    height="20%"
                    playing="true"
                  />
                </div>
                <div className='shapes-content'>
                  <p>"{d.text}"</p>
                  <div className='shapes-meta'> - {d.name} </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}

